import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/Home.vue';
import AboutPage from './components/About.vue';
import ContactPage from './components/Contact.vue';
import SharedDeck from './components/SharedDeck.vue';
import NotFound from './components/404.vue';
import TermsOfService from './components/TermsOfService.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import SupportPage from './components/Support.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/terms', component: TermsOfService },
  { path: '/privacy', component: PrivacyPolicy },
  { path: '/support', component: SupportPage },
  { path: '/about', component: AboutPage },
  { path: '/contact', component: ContactPage },
  { path: '/shared/:globalDeckId', component: SharedDeck },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;