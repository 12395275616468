<template>
  <div>
    <h1>About</h1>
    <p>Welcome to the Home Page.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>