<template>
  <div class="container general-container">
    <div class="text-container">
      <router-link to="/">
      <img src = "/logo-small.svg" class="homeLogo" alt="My Happy SVG"/>
    </router-link>
    <h1 class="textTitle">Terms of service</h1>
    <p class="bodyText">Welcome to this Terms of Use (the "Agreement"), this Agreement is a contract between you and Xavier Pedrals ("We","our" or "us") and we want you to know yours and our rights before you use our application (the "App" or "Software") and related services (the "Service"). Please take a few moments to read these Terms before enjoying the App, because once you access, view or use the App, we consider you to consent the terms and conditions described in this document. If you do not agree with this Agreement, please stop using the App immediately.</p>
    
    <p class="bodyText textSection">Scope of the App License</p>
    <p class="bodyText">1. We grant you a personal, revocable, non-transferable and non-exclusive license to use the App. Unless otherwise expressly agreed in writing, you are permitted to install, use, display or run the App or use the Service on your smart mobile end-device, including but not limited to tablet, smart phone ("end-device") for non-commercial purposes.</p>
    
    <p class="bodyText">2. You may not use the App under the following conditions:</p>
    
    <p class="bodyText">• you shall not create derivative works from the App or commercially exploit the App, in whole or in part, in any way;</p>
    
    <p class="bodyText">• you shall use the App for lawful purposes only;</p>   
    
    <p class="bodyText">• you shall not use, sell, modify, or distribute the App except as permitted by the functionality of the App;</p>
    
    <p class="bodyText">• you shall not conduct any behaviors that jeopardize computer network security, including but not limited to: use unauthorized data or unauthorized access to server/account；enter public computer network or other's computer system to delete, modify, add stored information without permission; attempt to search, scan, test the App system, Internet leak, or other behaviors breaking internet security; attempt to interfere, damage the App system normal running, deliver the malwares or virus intentionally to damage normal internet information service; forge (part of) names of TCP/IP package.</p>
    
    <p class="bodyText">3. You may not use the App in a way that may lead to damage, suspension, overloading or quality reduction to the App (or server and internet connected to the App), or in the way that may affect the Service provided.</p>
    
    <p class="bodyText">• You promise not to copy, grant a sub-license, share or sell the App or the Service to any others. You are fully responsible for any fees and expense arising therefrom.</p>
    
    <p class="bodyText">• We reserve all rights not expressly granted to you. And this license may automatically terminate if you violate any of these restrictions and may be terminated by us at any time at our sole discretion.</p>
    
    <p class="bodyText textSection">Privacy and Personal Information</p>
    <p class="bodyText">1. We respect the privacy and personal information of each user and will protect your privacy and personal information in accordance with applicable law, especially special law on data protection (GDPR) by taking necessary measures and comply with the same in collecting, processing, transmitting and storing your personal information. Please be sure to read carefully our Privacy Policy which specifies the way we collect and use your personal information, etc.</p>
    
    <p class="bodyText">2. The App may request your permissions to use your name,Birthdate, email, username, couuntry and type os subscription to inform you of new updates and commercial purposes.</p>
    
    <p class="bodyText textSection">Intellectual Property Rights</p>
    <p class="bodyText">1. You acknowledge and agree that we possess any and all the lawful rights and interests of the App, including any intellectual property rights involved in the Software and Service.</p>
    
    <p class="bodyText">• The information in the App includes but not limited to words, software, photos, all contents in advertisements, the commercial information we offer to you, etc., which are all protected under copyright, trademark as well as all other related laws and regulations. You are allowed to use these contents only when you have our authorization; no unauthorized reproduction, modification or compilation of these contents or creation of relevant derivative works are permitted.</p>
    
    <p class="bodyText">• Unless you have otherwise reached written agreement with us, this Agreement does not authorize you to use name of software, trademark, service mark, logo, domain name or any other sign with distinctive brand feature relating to us.</p>
    
    <p class="bodyText textSection">Uploaded content</p>
    
    <p class="bodyText">• As the Software user you may submit us graphics, photos and any other materials (collectively "Content"). You understand that, whether or not the Content is published, we do not guarantee any confidentiality with respect to such Content. You understand and agree that we cannot guarantee that you will be able to retrieve or save posted Content locally, and is not responsible for any loss of the Content.</p> 
    
    <p class="bodyText">• You retain all of your ownership rights in your Content submitted, however, by uploading, posting or otherwise transmitting your Content on or to the Software, you voluntarily grant to us a royalty-free, perpetual, irrevocable, transferable, worldwide, non-exclusive rights and licenses to use, sublicense, reproduce, modify, adapt, publish, display, translate, analyze, create derivative works from, distribute, derive revenue or other remuneration in the full term of any rights that may exist in such Content. You also permit other users to access, display, view, store and reproduce such Content for personal use under these terms of this Agreement.</p>
    
    <p class="bodyText">• When you provide your Content to us, you understand and accept complete responsibility for your Content, including any consequences that may arise. We do not endorse any Content or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with such Content. As such, you represent and warrant that you own or have (and will continue to have during your use of the Software) been granted all necessary licenses, rights, consents and permissions for such Content.</p>
    
    <p class="bodyText">• You agree that your conduct through the Software will comply with (and you agree that all of your Content shall comply with) this Agreement, as updated from time to time. You hereby warrant and represent that: (a) you are the sole owner of and/or hold all rights in and to your Content; (b) there are no restrictions, limitations which prevent or restrict you from granting the licenses herein; and (c) your Content will not: (i) facilitate or promote illegal activity, or contain content that is illegal; (ii) contain content that is defamatory, misleading, fraudulent, obscene, distasteful, racially or ethnically offensive, harassing, or that is discriminatory based upon race, gender, colour, creed, age, sexual orientation, or disability; (iii) contain sexually suggestive or explicit content; (iv) infringe upon or violate any right of any third party; (v) disparage, defame, or discredit us or any third party; (vi) contain any virus, viruses, worms, Trojan horses and other disabling or damaging codes; or (vii) otherwise contain content that violates any laws, rules, regulations or policies of any competent jurisdiction. You agree to defend, indemnify and hold harmless us and our directors, employees, shareholders, customers, agents, successors and assignees from and against any and all loss, damage, settlement or expense (including reasonable legal expenses) from and against any claim arising from breach of your representations and warranties set forth in this Agreement.</p>
    
    <p class="bodyText">• You agree that you will not post or upload any Content which it is unlawful for you to possess in the country in which you are resident, or which it would be unlawful for us to use or possess in connection with the provision of the Service.</p>  
    
    <p class="bodyText">• You agree that the Content you submit to the Service will not contain any third party copyright material, or material that is subject to other third party proprietary rights (including rights of privacy or rights of publicity), unless you have a formal license or permission from the rightful owner, or are otherwise legally entitled.</p>
    
    <p class="bodyText">• On becoming aware of any potential violation of this Agreement, we reserve the right (but shall have no obligation) to decide whether such Content complies with the content requirements set out in this Agreement, and may remove such Content and/or terminate a user's access for uploading such Content, at any time, without prior notice and at our sole discretion.</p>
    
    <p class="bodyText textSection">Paid Services</p>
    <p class="bodyText">• Some of the Services provided by the App will need to be paid for use ("Paid Services"), but we may provide a free trial period for use. Once the fees are duly paid in full, you will acquire the Paid Services within the subscription period. In the future, the Software may comprise further Paid Services. If you already subscribed the Paid Services during the period the further Paid Services is updated, you will be able to use the further Paid Services during the subscription period without extra charge, provided that: 1) no extra charge is required by us; 2) you update the Software as may be required to use the further Paid Services.</p>
    
    <p class="bodyText">• For any Paid Services offered by us, we accept payment via the current payment method indicated prior to purchase, which may include Apple Payments and any other form of payment that we make available to you from time to time. You agree to abide by any relevant terms of service or other legal agreement whether with Apple or a third party, that governs your use of a given payment processing method.</p>
    
    <p class="bodyText">• We could modify about our payment policy according to practical needs. Some free services may become Paid Services in the future. If the fee is not paid on time and in full, you will no longer be able to use the Paid Services when we start to charge fees. In the event there is a price reduction or promotion, we will not provide price protection or refund your payment. Please be aware that, as long as you purchase the paid service, we will not refund the payment to you whether or not you use such service.</p>
    
    <p class="bodyText textSection">Indemnification</p>
    <p class="bodyText">You hold us harmless from all claims, including claims for damages, that other users or any other third parties, including public agencies ("Third Parties"), assert against us on account of your violation of this Agreement and our Privacy Policy. You shall assume all reasonable costs, including the reasonable costs incurred for legal defense, that accrue to us as a result of your violation of the rights of other users or Third Parties. All further rights as well as claims for damages on the part of us shall remain unaffected.</p>
    
    <p class="bodyText textSection">Limitation of Liability and Disclaimer</p>
    <p class="bodyText">1. You acknowledge and agree that the App and the Service may have potential risks like service interruption, failure to respond to users' requests, due to force majeure, mobile communication terminal virus or hacker attack, system instability, user physical location, phone power off, and other reasons concerning technology, telecommunication lines. In no event shall we be liable for any risks stated above.</p>
    
    <p class="bodyText">2. We are not liable for any losses to users arising out of telecommunication line breakdown, technical problems, internet, mobile communication terminal failures, system instability and any other force majeure.</p>
    
    <p class="bodyText">• In view of business development and adjustment, we reserve the right to amend or terminate the Service without prior notice to users at any time, and we are not liable for users and any other third-parties when executing this right.</p>
    
    <p class="bodyText">• The Service which is not officially released or authorized by us and the derivative works of us are illegal. User's downloading, installation, and using this software may lead to unexpected risk. We are not liable for any legal liabilities, issues arising from it.</p>
    
    <p class="bodyText">• You agree to the most extent under applicable law, we own other disclaimer rights not listed in this agreement.</p>
    
    <p class="bodyText textSection">Use by Juveniles</p>
    <p class="bodyText">The App does not directly target any juvenile users. If you are a juvenile user and decide to use the App any way, please first seek the consent of your guardian and/or other adult with guardianship duties and use the App under the guidance and supervision of your guardian or any other adult with guardianship duties. In addition, your guardian and/or other adult with guardianship duties shall be responsible for assisting you in correctly understanding the contents of this Agreement and other related legal documents in order to guide you to use the App in a correct way.</p>
    
    <p class="bodyText textSection">Modifications</p>
    <p class="bodyText">We may revise these terms of use for the App at any time without notice. By using the App you are agreeing to be bound by the then current version of these Terms.</p>
    
    <p class="bodyText textSection">Update, Discontinuation, Suspension and Termination of the App </p>
    <p class="bodyText">• You have the right to stop using the App at any time.</p>
    
    <p class="bodyText">• The App may be updated from time to time. You may at your own discretion decide whether to accept the updates or not. We do not guarantee providing you with latest updated services if you do not accept the updates, and you shall be solely responsible for any loss that may be incurred.</p>
    
    <p class="bodyText">• We reserve the rights to amend, interrupt, suspend or terminate to provide the App based on the actual situation at any time, which includes but not limited to the memory space obtainable to a single user, the nature and quantity of the contents uploaded and other data or the ability of the users on continuously accessing the App and the Service, etc.</p>
    
    <p class="bodyText">• You agree that we may, without prior notice, suspend or terminate the Service related to the App if:</p>
    
    <p class="bodyText">• you are in breach of these Terms;</p>
    
    <p class="bodyText">• we do so subject to the applicable law or requirements of competent authority; or</p>
    
    <p class="bodyText">• ther condition upon which we need to do so.</p>
    
    <p class="bodyText">• Upon termination of the Service, we, subject to the applicable law and regulation, reserve the right to completely delete all information of you, and we shall no longer undertake obligations to you.</p>
    
    <p class="bodyText textSection">Others</p>
    <p class="bodyText">1. You agree that if we do not exercise or enforce any legal right or regulations, this will not be taken to be a formal waiver of our rights and we have the right to continuously exercise or enforce the right or regulation.</p>
    
    <p class="bodyText">• Without written authorization from other side, user may not assign or transfer the rights granted by these Terms, or assign the designated responsibilities and obligations to others.</p>
    
    <p class="bodyText">• We reserves the final interpretation right on these Terms.</p>
    
    <p class="bodyText textSection">Contact Us</p>
    <p class="bodyText">If you have any questions about the Service or this Agreement, or if you would like to obtain a previous version of the current document, feel free to contact us at support@ankiflashcards.app</p>
  </div>
<hr>
  <FooterComponent/>
  </div>
</template>

<style>
.general-container {
  background: #ffffff;
  box-shadow: 0 6px 20px 0 #CBD5E1;
}
.text-container {
  padding: 24px;
}
.homeLogo {
  margin-top: 30px;
}
.textTitle {
  margin-top: 45px;
  margin-bottom: 35px;
  font-weight: 600;
}
.textSection {
  font-size: 1.3em;
  font-weight: 600;
  margin-top: 40px;
}
.bodyText {
  text-align: justify;
}
@media (max-width: 576px) {
  .text-container {
    padding: 12px;
  }
}
</style>

<script>
import FooterComponent from './Footer.vue';

export default {
  name: 'TermsOfService',
  components: {
    FooterComponent
  }
};
</script>
